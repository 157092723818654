import "intersection-observer";
import Headroom from "headroom.js";
import sal from "sal.js";
import GLightbox from "glightbox";
// import "./sliders.js";

// loadClass
const theApp = document.getElementById("theApp");
theApp.classList.add("is-loaded");

// Sticky Header
const theHeader = document.getElementById("theHeader");
if (theHeader) {
  var headroom = new Headroom(theHeader, {
    offset: 100,
    classes: {
      initial: "is-sticky",
      // when scrolling up
      pinned: "is-pinned",
      // when scrolling down
      unpinned: "is-unpinned",
      // when above offset
      top: "is-top",
      // when below offset
      notTop: "is-not-top",
      // when at bottom of scoll area
      bottom: "is-bottom",
      // when not at bottom of scroll area
      notBottom: "is-not-bottom",
    },
  });
  headroom.init();
}

// Sal.js
sal({
  threshold: 0.1,
  once: true,
});

// #navOverlay
const navOverlay = document.querySelectorAll(".navOverlay")[0];
const navOverlayClose = document.querySelectorAll(".navOverlay-close");
const navOverlayOpen = document.querySelectorAll(".navOverlay-open");
if (navOverlayClose && navOverlay) {
  navOverlayClose.forEach((el) => {
    el.addEventListener("click", (event) => {
      navOverlay.classList.remove("is-active");
      // navOverlayOpen[0].classList.remove("is-active");
    });
  });
}
if (navOverlayOpen && navOverlay) {
  navOverlayOpen.forEach((el) => {
    el.addEventListener("click", (event) => {
      if (navOverlay.classList.contains("is-active")) {
        navOverlay.classList.remove("is-active");
        navOverlayOpen[0].classList.remove("is-active");
      } else {
        navOverlay.classList.add("is-active");
        navOverlayOpen[0].classList.add("is-active");
      }
    });
  });
}

// LazyLoad Images
window.addEventListener("load", (event) => {
  // Observer
  const inViewElms = document.querySelectorAll(".inViewJs");

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(
      (entry) => {
        const lazyImage = entry.target.querySelector("img[data-src]");
        if (entry.intersectionRatio > 0) {
          // console.log(lazyImage.querySelector("img[data-src]"));
          lazyImage.src = lazyImage.dataset.src;

          lazyImage.onload = function () {
            entry.target.classList.add("inViewJs-active");
            observer.unobserve(entry.target);
          };
        } else {
          // console.log("out of view");
          // entry.target.classList.remove("inViewJs-active");
        }
      },
      { rootMargin: "0px 0px 100px 0px" }
    );
  });

  inViewElms.forEach((elm) => {
    observer.observe(elm);
  });
});

// lihgtBox
const lightbox = GLightbox({ touchNavigation: true });
